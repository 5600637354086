<template>
	<div class="device-status">
		<div class="title">
			<span class="icon">
				<i :class="menuIcon"></i>
			</span>
			<span class="txt">{{menuName}}</span>
		</div>
		<el-table 
			class="table"
			ref="table"
			:data="recordList"
			:row-class-name="tableRowClassName"
			max-height="650"
			style="width: 100%;"
			:header-row-style="{height: '70px'}"
			:row-style="{height: '0'}"
			:cell-style="{padding: '0'}"
		>
			<el-table-column label="设备序列号" min-width="16%" align='center'>
				<template slot-scope="scope">
					<p>{{scope.row.equipmentNo | format}}</p>
				</template>
			</el-table-column>
			<el-table-column label="设备名称" min-width="16%" align='center'>
				<template slot-scope="scope">
					<p>{{scope.row.equipmentName | format}}</p>
				</template>
			</el-table-column>
			<el-table-column label="开始时间" min-width="16%" align='center'>
				<template slot-scope="scope">
					<p>{{scope.row.beginTime | format}}</p>
				</template>
			</el-table-column>
			<el-table-column label="结束时间" min-width="16%" align='center'>
				 <template slot-scope="scope">
					<p>{{scope.row.endTime | format}}</p>
				</template>
			</el-table-column>
			<el-table-column label="设备状态" min-width="16%" align='center'>
				<template slot-scope="scope">
					<p>{{scope.row.statusName | format}}</p>
				</template>
			</el-table-column>
			<el-table-column label="持续时间(S)" min-width="16%" align='center'>
				<template slot-scope="scope">
					<p>{{scope.row.continueTime | format}}</p>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {
		getRecordList
	} from 'network/deviceStatus'
	export default {
		name: "deviceStatus",
		data() {
			return {
				tableHeight: 430,
				userId: sessionStorage.getItem("userId"),
				recordList: []
			}
		},
		mounted() {
			//this.$refs.table.$el.offsetTop：表格距离浏览器的高度
			//100表示你想要调整的表格距离底部的高度（你可以自己随意调整）
			this.$nextTick(function () {
				this.tableHeight = window.innerHeight -  200;
				
				// 监听窗口大小变化
				let self = this;
				window.onresize = function() {
					self.tableHeight = window.innerHeight - 200;
				}
			})
		},
		created() {
			this.getRecordList1()
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		filters: {
			format(val) {
				return val == null ? "--" : val
			}
		},
		methods: {
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			getRecordList1() {
				let obj = {
					stationId: sessionStorage.getItem("stationId"),
					userId: this.userId
				}
				getRecordList(obj).then(res => {
					if(res.data.code !== 0)
					return this.$message.error("请求数据失败")
					this.recordList = res.data.data
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
.device-status {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 0.625rem;
	box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			text-align: center;
			line-height: 2.5rem;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.txt {
			height: 2.5rem;
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			line-height: 2.5rem;
			letter-spacing: 3px;
		}
	}
	.table {
		border-top: 1px solid #EBEEF5;
		overflow: hidden;
		overflow-y: scroll;
	}
}
</style>
